import React, {useState, useEffect} from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import SVG from "./SVG";

const GatsbySlideshow = ({images, overlays}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [currentOverlay, setCurrentOverylay] = useState(overlays[0]);

  const handleBullet = (e) => {
    setCurrentIndex(parseInt(e.target.getAttribute('data-index')));
  }

  if (!images || images.length === 0) {
    return <p>No images found.</p>;
  }

  const image = getImage(images[currentIndex]);

  return (
      <div className={'gatsby-slideshow'}>
        <div className="slideshow">
          <div class="button-container">
            <button
                className={'image-gallery-icon image-gallery-left-nav'}
                onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}>
              <SVG icon="left" viewBox="6 0 12 24"/>
            </button>
          </div>
          <div class="slideshow-container">
            <div class="overlay" dangerouslySetInnerHTML={{__html: overlays[currentIndex]}} />
            <GatsbyImage image={image} alt={`Slide ${currentIndex}`}/>
          </div>
          <div class="button-container">
            <button
                className={'image-gallery-icon image-gallery-right-nav'}
                onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}>
              <SVG icon="right" viewBox="6 0 12 24"/>
            </button>
          </div>
        </div>
        <div className="image-gallery-bullets">
          <div role="navigation" aria-label="Bullet Navigation" className="image-gallery-bullets-container">
            {images.map((item, index) => (
                <button
                    type="button"
                    aria-pressed={index === currentIndex ? 'true' : 'false'}
                    className={`image-gallery-bullet ${index === currentIndex ? 'active' : ''}`}
                    key={index}
                    data-index={index}
                    onClick={handleBullet}
                />
            ))}
          </div>
        </div>
      </div>
  );
};

export default GatsbySlideshow;
