import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import {I18nextContext, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import GatsbySlideshow from "../../components/carousel/gatsby-slideshow/GatsbySlideshow";
import {returnLocaleURL} from "../../utils/utils";

const IndexPage = (data) => {
  const d = data.data.Slides.edges;
  const images = [];
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  for(let i= 0; i<= d.length-1; i++) {
    images.push(d[i].node.childImageSharp);
  }
  const overlays = [
    '<span>canada</span><br>' +
    '<h2>Mount Royal University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Iowa State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Ohio State University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>University of Texas</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>Malaysia</span><br>' +
    '<h2>Alfa College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Mini Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Colorado College</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Loyola University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Princeton University</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>'

  ];
  return (
      <Layout>
        <Seo
            title={'slideshow'}
            description={'slideshow description'}
        />
        <div>
          <section>
            <div class="container">
              <GatsbySlideshow images={images} overlays={overlays} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    Slides: allFile(filter: {relativeDirectory: {eq: "solutions/education"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
      }
    }
  }
  
  }
`;
